/*
Template Name: Upzet -  Admin & Dashboard Template
Author: Themesdesign
Version: 1.1.0
Contact: themesdesign.in@gmail.com
File: Main Css File
*/

//Fonts
@import 'custom/fonts/fonts';

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'variables';
@import './node_modules/bootstrap/scss/mixins.scss';
// Structure
@import 'custom/structure/general';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/vertical';
@import 'custom/structure/horizontal-nav';
@import 'custom/structure/layouts';

// Components
@import 'custom/components/waves';
@import 'custom/components/avatar';
@import 'custom/components/accordion';
@import 'custom/components/helper';
@import 'custom/components/forms';
@import 'custom/components/demos';
@import 'custom/components/print';

// Plugins
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/bootstrap-touchspin';
@import 'custom/plugins/calendar';
@import 'custom/plugins/color-picker';
@import 'custom/plugins/custom-scrollbar';
@import 'custom/plugins/datatable';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/flot';
@import 'custom/plugins/form-editors';
@import 'custom/plugins/form-upload';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/google-map';
@import 'custom/plugins/parsley';
@import 'custom/plugins/range-slider';
@import 'custom/plugins/rating';
@import 'custom/plugins/responsive-table';
@import 'custom/plugins/select2';
@import 'custom/plugins/session-timeout';
@import 'custom/plugins/sparkline-chart';
@import 'custom/plugins/sweatalert2';
@import 'custom/plugins/switch';
@import 'custom/plugins/table-editable';
@import 'custom/plugins/vector-maps';
@import 'custom/plugins/x-editable';

// Pages
@import 'custom/pages/authentication';
@import 'custom/pages/email';
@import 'custom/pages/coming-soon';
@import 'custom/pages/timeline';
@import 'custom/pages/extras-pages';

.sortable-tr {
  user-select: none;
  cursor: pointer;
}

.drop-zone {
  border: 2px dashed #333;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

//
// vector-maps.scss
//

.jqvmap-label {
  background-color: $dark;
  color: $gray-200;
  font-size: $font-size-base;
  padding: 0.3rem 0.6rem;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  width: 24px;
  height: 24px;
  line-height: 18px;
}

.jqvmap-zoomout {
  top: 40px;
}

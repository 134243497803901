//
// Range slider
//

.irs {
  .irs--square {
    cursor: pointer;
  }
}

.irs--round {
  .irs-bar,
  .irs-to,
  .irs-from,
  .irs-single {
    background: $primary !important;
    font-size: 11px;
  }

  .irs-to,
  .irs-from,
  .irs-single {
    top: 5px;
    &:before {
      display: none;
    }
  }

  .irs-line {
    background: $gray-300;
    border-color: $gray-300;
    cursor: pointer;
  }
  .irs-grid-text {
    font-size: 11px;
    color: $gray-400;
  }
  .irs-min,
  .irs-max {
    color: $gray-500;
    background: $gray-300;
    font-size: 11px;
  }

  .irs-handle {
    border: 2px solid $primary;
    width: 12px;
    height: 12px;
    top: 31px;
    background-color: $card-bg !important;
    cursor: pointer;
    &:active {
      cursor: pointer;
    }
  }
}
